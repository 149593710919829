export enum StatusCodeRanges {
  LowestInfomational = 100,
  HighestInformational = 199,
  LowestSuccessfull = 200,
  HighestSuccessfull = 299,
  LowestRedirection = 300,
  HighestRedirection = 399,
  LowestClient = 400,
  HighestClient = 499,
  LowestServer = 500,
  HighestServer = 599,
}
